import { css } from "@emotion/react";

export default css`
  body {
    margin-right: calc(-1 * (100vw - 100%));
    font-family: "TT commons", sans-serif;
    padding: 0 !important;
    overflow-x: hidden;
  }

  input {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
