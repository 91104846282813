import { AnalyticsBrowser } from "@segment/analytics-next";

export const segment = new AnalyticsBrowser();

type SegmentEvent = {
  change_umbrella_account: { umbrella: string };

  // dashboard
  load_quick_facts: undefined;
  load_combined_stats: undefined;
  load_general_stats: undefined;
  load_total_hours_chart: undefined;
  load_total_sessions_chart: undefined;
  load_active_students_chart: undefined;
  load_power_users_hours: undefined;
  load_power_users_sessions: undefined;
  ll_load_general_stats: undefined;
  ll_load_hours_chart: undefined;
  ll_load_completed_chart: undefined;
  wl_load_general_stats: undefined;
  wl_load_hours_chart: undefined;
  wl_load_completed_chart: undefined;
  ll_v_wl_load_chart: undefined;
  change_date_range: { type: string; from?: string; to?: string };
  change_scale_range: { type: string };
  change_moderation_filter: { type: string };
  change_subject_filter: { subject: string };
  clear_filter: { filter: string };
  clear_all_filters: undefined;

  // lessons
  lessons_change_moderation_filter: { type: string };
  lessons_change_subject_filter: { subject: string };
  lessons_search: { query: string };
  lessons_change_date_range: { type: string; from?: string; to?: string };
  lessons_export: undefined;
  lesson_details: { lessonId: number };
  lesson_clever_filter: { cleverFacet: string | null };

  // wl
  wl_change_moderation_filter: { type: string };
  wl_search: { query: string };
  wl_change_date_range: { type: string; from?: string; to?: string };
  wl_export: undefined;
  wl_download_paper: { id: number };
  wl_paper_details: { id: number };
  wl_paper_feedback: { id: number };
  wl_clever_filter: { cleverFacet: string | null };

  // students
  students_search: { query: string };
  students_export: undefined;
  students_minutes_filter: { minutes: number };
  students_clever_filter: { cleverFacet: string | null };
};

export function trackEvent<T extends keyof SegmentEvent>(
  event: T,
  properties: SegmentEvent[T]
): void {
  segment.track(event, properties);
}
