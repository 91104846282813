import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import zIndex from "@material-ui/core/styles/zIndex";

import { default as Theme, Overrides } from "~/declarations/theme";

export const theme: Theme = {
  breakpoints: createBreakpoints({ unit: "px" }),
  typography: {
    fontFamily: `TT Commons, "sans-serif"`
  },
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff"
    },
    primary: {
      dark: "#39486f",
      main: "#067BC2",
      light: "#65add9",
      lighter: "#cde5f3"
    },
    secondary: {
      main: "#FED766",
      light: "#7ea2fc"
    },
    error: {
      main: "#FF0000",
      light: "#f45b69"
    },
    grey: {
      900: "#424242",
      800: "#666666",
      600: "#9e9e9e",
      400: "#dbdbdd",
      200: "#f3f3f3",
      50: "#fafafc"
    },
    data: {
      blue: {
        100: "#c1d8ff",
        300: "#9bcae7",
        500: "#6ab0da",
        700: "#3895ce",
        900: "#067bc2"
      },
      blueGrey: "#fafbff",
      turquoise: {
        100: "#def3f3",
        300: "#bde7e7",
        500: "#9bdbdb",
        600: "#7acfcf",
        900: "#59c3c3"
      }
    }
  },
  zIndex
};

const overrides: Overrides = {
  ...(typeof theme?.overrides === "object" ? theme?.overrides : {}),
  MuiPaper: {
    rounded: {
      borderRadius: "24px"
    }
  },
  MuiDialog: {
    paper: {
      borderRadius: "4px"
    }
  },
  MuiInput: {
    root: {
      "&$disabled": {
        color: theme.palette.grey["800"]
      }
    },
    underline: {
      "&:before": {
        borderBottom: `1px solid ${theme.palette.grey["400"]}`
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 25,
      "& > fieldset": {
        borderColor: theme.palette.grey["400"]
      }
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: "12px",
      textTransform: "uppercase"
    }
  },
  MuiTableCell: {
    root: {
      borderBottom: "unset"
    },
    head: {
      color: theme.palette.grey["600"],
      fontSize: "10px",
      textTransform: "uppercase",
      border: `1px solid ${theme.palette.grey["400"]}`,
      borderWidth: "1px 0 1px 0"
    }
  },
  MuiSelect: {
    root: {
      textTransform: "uppercase",
      marginRight: "15px"
    }
  },
  MuiPopover: {
    paper: {
      borderRadius: "5px"
    }
  },
  MuiAutocomplete: {
    root: {
      textTransform: "uppercase"
    },
    popper: {
      marginTop: "4px"
    },
    paper: {
      borderRadius: "4px",
      textTransform: "uppercase",
      boxShadow: `0px 0px 17px rgba(0, 0, 0, 0.14)`
    },
    inputRoot: {
      "& input": {
        textTransform: "uppercase",
        fontSize: "13px"
      },
      "&.MuiOutlinedInput-root": {
        padding: "2px 9px"
      }
    },
    listbox: {
      padding: 0
    },
    option: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    }
  }
};

export default createMuiTheme({ ...theme, overrides });
